import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Document, Page } from 'react-pdf';
import { Container, Alert, Box, Button, Paper } from '@mui/material';
import { getRelatorioEntrada } from '../../redux/actions/relatorioActions';
import LoadingMessage from '../../templates/Loading/LoadingMessage';
import { Download, Print } from '@mui/icons-material';

const RelatorioEntrada = () => {
    const dispatch = useDispatch();
    const [numPages, setNumPages] = useState(null);

    const { loading, pdf, error } = useSelector((state) => state.getRelatorioEntradaStore);

    useEffect(() => {
        dispatch(getRelatorioEntrada());
    }, [dispatch]);

    const handleDownload = () => {
        if (pdf) {
            const link = document.createElement('a');
            link.href = pdf;
            link.setAttribute('download', 'relatorio.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handlePrint = () => {
        if (pdf) {
            const iframe = document.createElement('iframe');
            iframe.style.position = 'fixed';
            iframe.style.width = '100%';
            iframe.style.height = '100%';
            iframe.style.top = '0';
            iframe.style.left = '0';
            iframe.style.border = 'none';
            iframe.style.zIndex = '-1';
            iframe.src = pdf;
            document.body.appendChild(iframe);
            iframe.onload = () => {
                iframe.contentWindow.focus();
                iframe.contentWindow.print();
                document.body.removeChild(iframe);
            };
        }
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    return (
        <Container sx={{height: '100vh', display: 'flex', flexWrap:"wrap", justifyContent: 'center', alignItems: 'center' }}>
            {loading && <LoadingMessage />}
            {error && <Alert severity="error">{error}</Alert>}
            {pdf && (
                <Paper sx={{padding: 2, width: "75%", display: 'flex', flexDirection: 'column', flexWrap:"wrap", alignItems: 'center' }}>
                    <Box sx={{ 
                        width: '210mm', 
                        height: '297mm', 
                        //height: '30vh',
                        overflow: 'hidden', 
                        //overflowY: 'auto',
                        display: 'flex', 
                        flexWrap:"wrap",
                        justifyContent: 'center', 
                        alignItems: 'center',
                        border: '1px solid #000' // Opcional: adicionar uma borda para visualização clara
                    }}>
                        <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} >
                            <Page pageNumber={1} scale={1.3} /> {/* 595.28px é a largura de um A4 em 72dpi */}
                        </Document>
                    </Box>
                    <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Button variant="contained" color="primary" onClick={handleDownload} style={{marginLeft:"auto"}}>
                            <Download/>
                        </Button>
                        <Button variant="contained" color="secondary" onClick={handlePrint} style={{ marginRight:"auto"}}>
                            <Print/>
                        </Button>
                    </Box>
                </Paper>
            )}
        </Container>
    );
};

export default RelatorioEntrada;
