import { configureStore } from "@reduxjs/toolkit";
import { applyMiddleware, combineReducers, compose } from "redux";
import thunk from "redux-thunk";

import * as entrada from "./reducers/entradaReducer.jsx";
import * as movimento from "./reducers/movimentoReducer.jsx";
import * as saida from "./reducers/saidaReducer.jsx";
import * as login from "./reducers/authReducers.jsx";
import * as resumo from "./reducers/resumoReducers.jsx";
import * as relatorio from "./reducers/relatorioReducer.jsx";


const reducer = combineReducers({
  // movimentos store
  getAllMovimentoStore: movimento.getAllMovimentosReducer,

  // entradas store
  getAllEntradaStore: entrada.getAllEntradasReducer,
  getAllEntradasChartStore: entrada.getAllEntradasChartReducer,
  getEntradaStore: entrada.getEntradasReducer,
  getCountEntradaStore: entrada.getCountEntradasReducer,
  newEntradaStore: entrada.newEntradaReducer, 
  updateEntradaStore: entrada.updateEntradaReducer,
  deleteEntradaStore: entrada.deleteEntradaReducer,

  // saidas store
  getAllSaidaStore: saida.getAllSaidasReducer,
  getSaidasSumStore: saida.getSaidasSumReducer,
  getCountSaidaStore: saida.getCountSaidasReducer,
  newSaidaStore: saida.newSaidaReducer,
  updateSaidaStore: saida.updateSaidaReducer,
  deleteSaidaStore: saida.deleteSaidaReducer,

  // auth store
  userLoginStore: login.loginReducer,
  addUserStore: login.registroReducer,
  getAllUserStore: login.getAllUsersReducer,

  // resumo store
  getResumoStore: resumo.getResumoReducer,

  // relatorio
  getRelatorioEntradaStore: relatorio.relatorioEntradaReducer,

});

const compositor = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = configureStore({ reducer }, compositor(applyMiddleware(thunk)));

export default store;
