import {relatorioTypes } from '../constants/relatorioTypes';

const initialState = {
    loading: false,
    pdf: null,
    error: ''
};

export function relatorioEntradaReducer (state = initialState, action)  {
    switch (action.type) {
        case relatorioTypes.REQ_GET_RELATORIO:
            return {
                ...state,
                loading: true
            };
        case relatorioTypes.SUCCESS_GET_RELATORIO:
            return {
                loading: false,
                pdf: action.payload,
                error: ''
            };
        case relatorioTypes.FAIL_GET_RELATORIO:
            return {
                loading: false,
                pdf: null,
                error: action.payload
            };
        default:
            return state;
    }
};
