export const authTypes = {
    USUARIO_LOGIN_REQ:"USUARIO_LOGIN_REQ",
    USUARIO_LOGIN_SUCESSO:"USUARIO_LOGIN_SUCESSO",
    USUARIO_LOGIN_FALHOU:"USUARIO_LOGIN_FALHOU",

    USUARIO_REGISTRO_REQ:"USUARIO_REGISTRO_REQ",
    USUARIO_REGISTRO_SUCESSO:"USUARIO_REGISTRO_SUCESSO",
    USUARIO_REGISTRO_RESET:"USUARIO_REGISTRO_RESET",
    USUARIO_REGISTRO_FALHOU:"USUARIO_REGISTRO_FALHOU",

    USUARIO_DETALHE_REQ:"USUARIO_DETALHE_REQ",
    USUARIO_DETALHE_SUCESSO:"USUARIO_DETALHE_SUCESSO",
    USUARIO_DETALHE_FALHOU:"USUARIO_DETALHE_FALHOU",

    USUARIO_GET_REQ:"USUARIO_GET_REQ",
    USUARIO_GET_SUCESSO:"USUARIO_GET_SUCESSO",
    USUARIO_GET_FALHOU:"USUARIO_GET_FALHOU",

    USUARIO_ATUALIZAR_PERFIL_REQ:"USUARIO_ATUALIZAR_PERFIL_REQ",
    USUARIO_ATUALIZAR_PERFIL_SUCESSO:"USUARIO_ATUALIZAR_PERFIL_SUCESSO",
    USUARIO_ATUALIZAR_PERFIL_FALHOU:"USUARIO_ATUALIZAR_PERFIL_FALHOU",
    USUARIO_ATUALIZAR_PERFIL_RESET:"USUARIO_ATUALIZAR_PERFIL_RESET",

    USUARIO_SAIR:"USUARIO_SAIR"
}