import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts/core';
import { Card, CardHeader, Typography } from '@mui/material';

const CountSaidas = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = echarts.init(chartRef.current);

    const option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: '2%',
        left: 'center',
        orient: 'horizontal'
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 10,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: data.map((entry) => ({ value: entry.Total, name: entry.Area }))
        }
      ]
    };

    chart.setOption(option);
  }, [data]);

  return (
    <>
      <Card style={{width:"100%"}}>
        <CardHeader style={{textAlign:"center"}} title="TOP 5 AREA COM MAIS DESPESAS" />
        <Typography ref={chartRef} style={{ width: '100%', height: '350px', display: "flex", justifyContent: "center", alignItems: "center"}} />
      </Card>
    </>
  )
};

export default CountSaidas;
