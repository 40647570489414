import { saidaTypes } from "../constants/saidaTypes";

const initialState = {
    saidas: [],
    countSaidas: []
}

export function getAllSaidasReducer (state = initialState, action) { 
    switch (action.type) {

        case saidaTypes.REQ_GET_SAIDA:
            return {
                loading: true,
            };

        case saidaTypes.SUCCESS_GET_SAIDA:
            return {
                loading: false,
                state,
                saidas: [...action.payload]
            };

        case saidaTypes.FAIL_GET_SAIDA:
            return {
                loading: false,
                error: [...action.payload]
            };

        default:
            return state

    }
};

export function getSaidasSumReducer (state = initialState, action) { 
    switch (action.type) {

        case saidaTypes.REQ_GET_SAIDA_CHART:
            return {
                loading: true,
            };

        case saidaTypes.SUCCESS_GET_SAIDA_CHART:
            return {
                loading: false,
                state,
                saidas: [...action.payload]
            };

        case saidaTypes.FAIL_GET_SAIDA_CHART:
            return {
                loading: false,
                error: [...action.payload]
            };

        default:
            return state

    }
};

export function getCountSaidasReducer (state = initialState, action) { 
    switch (action.type) {

        case saidaTypes.REQ_GET_SAIDA_COUNT:
            return {
                loading: true,
            };

        case saidaTypes.SUCCESS_GET_SAIDA_COUNT:
            return {
                loading: false,
                state,
                countSaidas: [...action.payload]
            };

        case saidaTypes.FAIL_GET_SAIDA_COUNT:
            return {
                loading: false,
                error: [...action.payload]
            };

        default:
            return state

    }
};

export function newSaidaReducer (state = initialState, action) {
    switch (action.type) {
        case saidaTypes.REQ_INSERT_SAIDA:
            return { loading: true }

        case saidaTypes.SUCCESS_INSERT_SAIDA:
            //state.push(action.payload)
            return {
                ...state,
                success: true,
                loading: false,
                //countries: [...action.payload]
            }

        case saidaTypes.FAIL_INSERT_SAIDA:
            return {
                loading: false,
                error: [...action.payload]
            }

        case saidaTypes.RESET_INSERT_SAIDA:
            return {
                ...state,
                countries: []
            }

        default:
            return state

    }
}

export function updateSaidaReducer (state = initialState, action) {
     switch (action.type) {
         case saidaTypes.REQ_UPDATE_SAIDA:
             return { loading: true }

         case saidaTypes.SUCCESS_UPDATE_SAIDA:
            
             return {
                success: true,
                loading: false,
             };

         case saidaTypes.FAIL_UPDATE_SAIDA:
             return {
                 loading: false,
                 error: action.payload
             }

         case saidaTypes.RESET_UPDATE_SAIDA:
             return {
                 ...state,
                 saidas: []
             }

         default:
             return state

     }
}

 export function deleteSaidaReducer (state = initialState, action) {
     switch (action.type) {
         case saidaTypes.REQ_DELETE_SAIDA:
            return { loading: true }

         case saidaTypes.SUCCESS_DELETE_SAIDA:
            return {
               loading: false,
               success: true
            };

         case saidaTypes.FAIL_DELETE_SAIDA:
            return {
               loading: false,
               error: action.payload
            }

         default:
            return state

     }
}