import { entradaTypes } from "../constants/entradaTypes";

const initialState = {
    entradas: [],
    countEntradas:[],
    success: false,
}

export function getAllEntradasReducer (state = initialState, action) { 
    switch (action.type) {

        case entradaTypes.REQ_GET_ENTRADA:
            return {
                loading: true,
            };

        case entradaTypes.SUCCESS_GET_ENTRADA:
            return {
                loading: false,
                state,
                entradas: [...action.payload]
            };

        case entradaTypes.FAIL_GET_ENTRADA:
            return {
                loading: false,
                error: [...action.payload]
            };

        default:
            return state

    }
};

export function getAllEntradasChartReducer (state = initialState, action) { 
    switch (action.type) {

        case entradaTypes.REQ_GET_ENTRADA_CHART:
            return {
                loading: true,
            };

        case entradaTypes.SUCCESS_GET_ENTRADA_CHART:
            return {
                loading: false,
                state,
                entradas: [...action.payload]
            };

        case entradaTypes.FAIL_GET_ENTRADA_CHART:
            return {
                loading: false,
                error: [...action.payload]
            };

        default:
            return state

    }
};

export function getCountEntradasReducer (state = initialState, action) { 
    switch (action.type) {

        case entradaTypes.REQ_GET_ENTRADA_COUNT:
            return {
                loading: true,
            };

        case entradaTypes.SUCCESS_GET_ENTRADA_COUNT:
            return {
                loading: false,
                state,
                countEntradas: [...action.payload]
            };

        case entradaTypes.FAIL_GET_ENTRADA_COUNT:
            return {
                loading: false,
                error: [...action.payload]
            };

        default:
            return state

    }
};

export function getEntradasReducer (state = initialState, action) { 
    switch (action.type) {

        case entradaTypes.REQ_GET_ENTRADA:
            return {
                loading: true,
            };

        case entradaTypes.SUCCESS_GET_ENTRADA:
            return {
                loading: false,
                state,
                entradas: [...action.payload]
            };

        case entradaTypes.FAIL_GET_ENTRADA:
            return {
                loading: false,
                error: [...action.payload]
            };

        default:
            return state

    }
};

export function newEntradaReducer (state = initialState, action) {
    switch (action.type) {
        case entradaTypes.REQ_INSERT_ENTRADA:
            return { loading: true }

        case entradaTypes.SUCCESS_INSERT_ENTRADA:
            return {
                ...state,
                entradas: [state.entradas, action.payload],
                success: true,
                loading: false,
            }

        case entradaTypes.FAIL_INSERT_ENTRADA:
            return {
                loading: false,
                error: [...action.payload]
            }

        case entradaTypes.RESET_INSERT_ENTRADA:
            return {
                ...state,
            }

        default:
            return state

    }
}

export function updateEntradaReducer (state = initialState, action) {
     switch (action.type) {
         case entradaTypes.REQ_UPDATE_ENTRADA:
             return { loading: true }

         case entradaTypes.SUCCESS_UPDATE_ENTRADA:
            
             return {
                success: true,
                loading: false,
             };

         case entradaTypes.FAIL_UPDATE_ENTRADA:
             return {
                 loading: false,
                 error: action.payload
             }
         default:
             return state

     }
}

export function deleteEntradaReducer (state = initialState, action) {
     switch (action.type) {
         case entradaTypes.REQ_DELETE_ENTRADA:
            return { loading: true }

         case entradaTypes.SUCCESS_DELETE_ENTRADA:
            return {
               loading: false,
               success: true
            };

         case entradaTypes.FAIL_DELETE_ENTRADA:
            return {
               loading: false,
               error: action.payload
            }

         default:
            return state

     }
}
