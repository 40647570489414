// NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button, Container } from '@mui/material';

const NotFound = () => {
  return (
    <Container>
      <Typography variant="h1">404</Typography>
      <Typography variant="h5">Página não encontrada</Typography>
      <Button component={Link} to="/" variant="contained" color="primary">
        Voltar para a Página Inicial
      </Button>
    </Container>
  );
};

export default NotFound;
