import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Paper,
  TextField,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { newSaida } from "../../redux/actions/saidaActions";
import { saidaTypes } from "../../redux/constants/saidaTypes";
import MessageError from "../../templates/Error/MessageError";
import LoadingMessage from "../../templates/Loading/LoadingMessage";
import SuccessMessage from "../../templates/Success/SuccessMessage";

const saidaSchema = Yup.object().shape({
  DescricaoSaida: Yup.string().required("Campo obrigatório"),
  Beneficiario: Yup.string().required("Campo obrigatório"),
  ValorSaida: Yup.number().required("Campo obrigatório"),
});

const initialValues = {
  DescricaoSaida: "",
  ValorSaida: 0,
  Beneficiario: "",
};

const AddSaida = () => {
  const newSaidaStore = useSelector((state) => state.newSaidaStore);
  const { loading, error, success } = newSaidaStore;
  const dispatch = useDispatch();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    dispatch(newSaida(values));
    setSubmitting(false);
    setTimeout(() => {
      resetForm();
    }, 2000);
    console.log('Form submitted with values:', values);
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        dispatch({ type: saidaTypes.RESET_INSERT_SAIDA });
      }, 3000);
    }
  }, [dispatch, success]);

  return (
    <Container>
      <Paper component="div" elevation={2} className="offset-lg-3 col-lg-6">
        {loading && <LoadingMessage />}
        {error && (
          <MessageError variant="danger">
            Erro no cadastro: {error}
          </MessageError>
        )}
        {success && (
          <SuccessMessage variant="success">
            {`${initialValues.DescricaoSaida} Saida registrada com sucesso`}
          </SuccessMessage>
        )}
        <Box sx={{ padding: "2%", marginTop: "20%" }}>
          <Card>
            <CardHeader
              sx={{
                textAlign: "center",
              }}
              title="FORMULARIO DE REGISTROS DE SAIDAS"
            />
            <CardContent>
              <Formik
                initialValues={initialValues}
                validationSchema={saidaSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched }) => (
                  <Form>
                    <Box sx={{ justifyContent: "space-between" }}>
                      <Field
                        as={TextField}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="DescricaoSaida"
                        label="Descrição"
                        type="DescricaoSaida"
                        id="DescricaoSaida"
                        autoComplete="current-DescricaoSaida"
                        error={errors.DescricaoSaida && touched.DescricaoSaida}
                      />
                      <ErrorMessage
                        name="DescricaoSaida"
                        component="div"
                        className="error-message"
                      />

                      <Field
                        as={TextField}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="Beneficiario"
                        label="Beneficiario"
                        type="Beneficiario"
                        id="Beneficiario"
                        autoComplete="current-Beneficiario"
                        error={errors.Beneficiario && touched.Beneficiario}
                      />
                      <ErrorMessage
                        name="Beneficiario"
                        component="div"
                        className="error-message"
                      />
                      <Field
                        as={TextField}
                        variant="outlined"
                        margin="normal"
                        required
                        name="ValorSaida"
                        label="ValorSaida"
                        type="number"
                        id="ValorSaida"
                        sx={{ minWidth: "14.7rem" }}
                        autoComplete="current-valorSaida"
                        error={errors.ValorSaida && touched.ValorSaida}
                      />
                      <ErrorMessage
                        name="ValorSaida"
                        component="div"
                        className="error-message"
                      />
                    </Box>
                    <Button variant="contained" type="submit" fullWidth>
                      Validar
                    </Button>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Box>
      </Paper>
    </Container>
  );
};

export default AddSaida;
