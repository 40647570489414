import axios from "axios";
import { saidaTypes } from "../constants/saidaTypes";

const baseUrl = process.env.REACT_APP_BASE_URL + "/saidas";

export const getAllSaidas =(dataInicial,dataFinal,op) => async (dispatch, getState) => {

    dispatch({
        type: saidaTypes.REQ_GET_SAIDA,
    });
    try {
        // api request
        const {userLoginStore: {infoUsuario}} = getState(); 
        const { data } = await axios.get(baseUrl, { 
            params:{dataInicial, dataFinal, op},
            headers:{ authorization: `Bearer ${infoUsuario.Token}`}
        });
        dispatch({
            type: saidaTypes.SUCCESS_GET_SAIDA,
            payload: data
        });
        
    } catch (error) { 
        dispatch({
            type: saidaTypes.FAIL_GET_SAIDA,
            payload: error.message
        });
    }
}

export const getSaidasSum =(dataInicial,dataFinal,op) => async (dispatch, getState) => {

    dispatch({
        type: saidaTypes.REQ_GET_SAIDA_CHART,
    });
    try {
        // api request
        const {userLoginStore: {infoUsuario}} = getState(); 
        const { data } = await axios.get(`${baseUrl}/gettotalsaidadia`, { 
            params:{dataInicial, dataFinal, op},
            headers:{ authorization: `Bearer ${infoUsuario.Token}`}
        });
        dispatch({
            type: saidaTypes.SUCCESS_GET_SAIDA_CHART,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: saidaTypes.FAIL_GET_SAIDA_CHART,
            payload: error.message
        });
    }
}

export const getCountSaidas =(dataInicial,dataFinal,op) => async (dispatch, getState) => {

    dispatch({
        type: saidaTypes.REQ_GET_SAIDA_COUNT,
    });
    try {
        // api request
        const {userLoginStore: {infoUsuario}} = getState(); 
        const { data } = await axios.get(`${baseUrl}/getcountsaida`, { 
            params:{dataInicial, dataFinal, op},
            headers:{ authorization: `Bearer ${infoUsuario.Token}`}
        });
        dispatch({
            type: saidaTypes.SUCCESS_GET_SAIDA_COUNT,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: saidaTypes.FAIL_GET_SAIDA_COUNT,
            payload: error.message
        });
    }
}

export const newSaida =(saida) => async (dispatch, getState) => {
    dispatch({type: saidaTypes.REQ_INSERT_SAIDA});
    try {
        //api request
        const {userLoginStore: {infoUsuario}} = getState(); 
        const { data } = await axios.post(baseUrl, saida, { 
            headers:{ authorization: `Bearer ${infoUsuario.Token}`}
        })
        dispatch({
            type: saidaTypes.SUCCESS_INSERT_SAIDA,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: saidaTypes.FAIL_INSERT_SAIDA,
            payload: error.message
        });
    }
}

export const updateSaida =(saida) => async (dispatch, getState) => {
    dispatch({type: saidaTypes.REQ_UPDATE_SAIDA});
    try {
        //api request
        const {userLoginStore: {infoUsuario}} = getState(); 
        //const { data } = await axios.put(`${baseUrl}/${saida.Id}`, saida,{ 
        const { data } = await axios.put(
            baseUrl,saida,{ 
                headers:{ authorization: `Bearer ${infoUsuario.Token}`,
                },
            params: {id: saida.Id}
        })
        dispatch({
            type: saidaTypes.SUCCESS_UPDATE_SAIDA,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: saidaTypes.FAIL_UPDATE_SAIDA,
            payload: error.message
        });
    }
}

export const deleteSaida =(saida) => async (dispatch, getState) => {
    dispatch({type: saidaTypes.REQ_DELETE_SAIDA});
    try {
        //api request
        const {userLoginStore: {infoUsuario}} = getState(); 
        const { data } = await axios.delete(
            baseUrl,{ 
                headers:{ authorization: `Bearer ${infoUsuario.Token}`,
                },
            params: {id: saida.Id}
        })
        dispatch({
            type: saidaTypes.SUCCESS_DELETE_SAIDA,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: saidaTypes.FAIL_DELETE_SAIDA,
            payload: error.message
        });
    }
}

