import axios from "axios";
import { resumoTypes } from "../constants/resumoTypes";

const baseUrl = process.env.REACT_APP_BASE_URL + "/resumo";

export const getResmo = (dataInicial, dataFinal) => async (dispatch, getState) => {
    dispatch({
        type: resumoTypes.REQ_GET_RESUMO,
    });
    // api request
    const {userLoginStore: {infoUsuario}} = getState(); 
    try {
        const { data } = await axios.get(`${baseUrl}`, { 
            params:{dataInicial, dataFinal},
            headers:{ authorization: `Bearer ${infoUsuario.Token}`}
        });
        dispatch({
            type: resumoTypes.SUCCESS_GET_RESUMO,
            payload: data
        });
        
    } catch (error) {
        const message = 
        error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch({
            type: resumoTypes.FAIL_GET_RESUMO,
            payload: message
        });
    }
}