
export const entradaTypes = {
    // GETALL
    REQ_GET_ENTRADA: 'REQ_GET_ENTRADA',
    SUCCESS_GET_ENTRADA: 'SUCCESS_GET_ENTRADA',
    FAIL_GET_ENTRADA: 'FAIL_GET_ENTRADA',

    // GETCOUNT
    REQ_GET_COUNT: 'REQ_GET_COUNT',
    SUCCESS_GET_COUNT: 'SUCCESS_GET_COUNT',
    FAIL_GET_COUNT: 'FAIL_GET_COUNT',

    //CHARTS
    REQ_GET_ENTRADA_CHART: 'REQ_GET_ENTRADA_CHART',
    SUCCESS_GET_ENTRADA_CHART: 'SUCCESS_GET_ENTRADA_CHART',
    FAIL_GET_ENTRADA_CHART: 'FAIL_GET_ENTRADA_CHART',

    
    //CHARTS
    REQ_GET_ENTRADA_COUNT: 'REQ_GET_ENTRADA_COUNT',
    SUCCESS_GET_ENTRADA_COUNT: 'SUCCESS_GET_ENTRADA_COUNT',
    FAIL_GET_ENTRADA_COUNT: 'FAIL_GET_ENTRADA_COUNT',

    //UPDATE
    REQ_UPDATE_ENTRADA: 'REQ_UPDATE_ENTRADA',
    SUCCESS_UPDATE_ENTRADA: 'SUCCESS_UPDATE_ENTRADA',
    FAIL_UPDATE_ENTRADA: 'FAIL_UPDATE_ENTRADA',

    //DELETE
    REQ_DELETE_ENTRADA: 'REQ_DELETE_ENTRADA',
    SUCCESS_DELETE_ENTRADA: 'SUCCESS_DELETE_ENTRADA',
    FAIL_DELETE_ENTRADA: 'FAIL_DELETE_ENTRADA',

    //INSERT
    REQ_INSERT_ENTRADA: 'REQ_INSERT_ENTRADA',
    SUCCESS_INSERT_ENTRADA: 'SUCCESS_INSERT_ENTRADA',
    FAIL_INSERT_ENTRADA: 'FAIL_INSERT_ENTRADA',
    RESET_INSERT_ENTRADA: 'RESET_INSERT_ENTRADA',
}
