import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Zoom, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Delete, Save } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import LoadingMessage from "../../templates/Loading/LoadingMessage";
import MessageError from "../../templates/Error/MessageError";
import SuccessMessage from "../../templates/Success/SuccessMessage";
import { deleteEntrada, updateEntrada } from "../../redux/actions/entradaActions";

const validationSchema = Yup.object().shape({
  DescricaoEntrada: Yup.string().required("Campo obrigatório"),
  ValorEntrada: Yup.number().required("Campo obrigatório"),
  FonteEntrada: Yup.string().required("Campo obrigatório"),
  FormaPagamento: Yup.string().required("Campo obrigatório"),
});

const EntradaDialog = ({ item, onClose }) => {
  const dispatch = useDispatch();

  const updateEntradaStore = useSelector((state) => state.updateEntradaStore);
  const { loading, error, success } = updateEntradaStore;

  const deleteEntradaStore = useSelector((state) => state.deleteEntradaStore);
  const { loading: loadingDelete, error: errorDelete, success: successDelete } = deleteEntradaStore;

  const [updateformSubmitted, setUpdateFormSubmitted] = useState(false);
  const [deleteformSubmitted, setDeleteFormSubmitted] = useState(false);

  const handleSubmitUpdate = async (values, { setSubmitting }) => {
    dispatch(updateEntrada(values));
    setSubmitting(false);
    setUpdateFormSubmitted(true);
    setTimeout(() => {
      onClose();
    }, 5000);
  };

  const handleSubmitDelete = async () => {
    try {
      if (window.confirm(`Deseja eliminar o item nº ${item.Id} ?`)) {
        dispatch(deleteEntrada(item));
        setDeleteFormSubmitted(true);
        setTimeout(() => {
          onClose();
        }, 5000);
      }
    } catch (error) {
      console.error("Erro ao excluir:", error);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      TransitionComponent={Zoom}
      TransitionProps={{
        direction: "up",
        timeout: { enter: 1000, exit: 500 }, // Ajuste os valores de timeout conforme necessário
      }}
    >
      <DialogTitle textAlign="center">Editar Entrada Nº {item.Id}</DialogTitle>
      <DialogContent>
        {updateformSubmitted && loading && <LoadingMessage />}
        {updateformSubmitted && error && (
          <MessageError variant="danger">
            Erro de atualização: {error}
          </MessageError>
        )}
        {updateformSubmitted && success && (
          <SuccessMessage variant="success">
            {`registro nº ${item.Id} atualizado com sucesso`}
          </SuccessMessage>
        )}
        {deleteformSubmitted && loadingDelete && <LoadingMessage />}
        {deleteformSubmitted && errorDelete && (
          <MessageError variant="danger">
            Erro de exclusão: {errorDelete}
          </MessageError>
        )}
        {deleteformSubmitted && successDelete && (
          <SuccessMessage variant="success">
            {`registro nº ${item.Id} deletado com sucesso`}
          </SuccessMessage>
        )}
        <Formik
          initialValues={{ ...item }}
          validationSchema={validationSchema}
          onSubmit={handleSubmitUpdate}
        >
          {({ values, handleChange, handleBlur, errors, touched }) => (
            <Form>
              <Field
                as={TextField}
                variant="outlined"
                margin="normal"
                label="Descrição"
                required
                fullWidth
                name="DescricaoEntrada"
                value={values.DescricaoEntrada}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.DescricaoEntrada && touched.DescricaoEntrada)}
                helperText={<ErrorMessage name="DescricaoEntrada" />}
              />
              <Field
                as={TextField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Origem"
                name="FonteEntrada"
                value={values.FonteEntrada}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.FonteEntrada && touched.FonteEntrada)}
                helperText={<ErrorMessage name="FonteEntrada" />}
              />
              <Field
                as={TextField}
                name="ValorEntrada"
                label="Valor"
                variant="outlined"
                margin="normal"
                type="number"
                value={values.ValorEntrada}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.ValorEntrada && touched.ValorEntrada)}
                helperText={<ErrorMessage name="ValorEntrada" />}
              />
              <FormControl fullWidth sx={{ m: 2, maxWidth: "14rem" }}>
                <InputLabel id="formaPagamento">FORMA DE PAGAMENTO</InputLabel>
                <Field
                  as={Select}
                  name="FormaPagamento"
                  label="Forma de Pagamento"
                  variant="outlined"
                  required
                  value={values.FormaPagamento}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(errors.FormaPagamento && touched.FormaPagamento)}
                  helperText={<ErrorMessage name="FormaPagamento" />}
                >
                  <MenuItem value={"CASH"}>CASH</MenuItem>
                  <MenuItem value={"TRANSFERENCIA"}>TRANSFERÊNCIA</MenuItem>
                  <MenuItem value={"TPA"}>TPA</MenuItem>
                </Field>
              </FormControl>
              <DialogActions>
                <Button onClick={handleSubmitDelete} variant="contained" color="secondary">
                  <Delete />
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  <Save />
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default EntradaDialog;
