import React, { useEffect, useState } from 'react';
import { Card, Container, Typography } from '@mui/material';

export const SaldoCard = ({ data }) => {
    const [totalValor, setTotalValor] = useState(0);

    useEffect(() => {
        if (data) {
            const saldoRemanescente = data.Remanescente;

            const formattedValue = new Intl.NumberFormat("pt-AO", {
                style: "currency",
                currency: "AOA"
            }).format(saldoRemanescente);

            setTotalValor(formattedValue);
        }
    }, [data]);

    return (
        <Container component="div" elevation={2}>
            <Card sx={{ backgroundColor: '#3e85e3', padding: '5px' }}>
                <Typography textAlign="center" color="white">
                    MONTANTE RESTANTE <br /> {totalValor}
                </Typography>
            </Card>
        </Container>
    );
};
