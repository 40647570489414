import React, { useState } from "react";
import {Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { 
  AddEntrada, 
  AddSaida, 
  Dashboard, 
  Entrada, 
  Inicio, 
  Login,  
  NotFound, 
  RelatorioEntrada, 
  Saida, 
  SignUp, 
  Users 
} from "./pages";
import SidebarComponent from "./templates/SidebarComponent";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useSelector } from "react-redux";

// Defina as cores para o modo claro
const lightTheme = createTheme({
  palette: {
    mode: 'light',
    // Defina suas cores claras aqui
  },
});

// Defina as cores para o modo escuro
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    // Defina suas cores escuras aqui
  },
});

function App() {
  const [darkMode, setDarkMode] = useState(false);

  const userLoginStore = useSelector((state) => state.userLoginStore)
  const {infoUsuario} = userLoginStore;

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <Router>
          <SidebarComponent toggleDarkMode={toggleDarkMode} darkMode={darkMode}>
            <Routes>
              <Route  path='/' element={<Inicio />} />
              <Route  path="/entradas" element={ infoUsuario ? <Entrada /> : <Navigate to="/auth/login" />}   />
              <Route  path="/entradas/add" element={ infoUsuario ? <AddEntrada /> : <Navigate to="/auth/login" />}   />
              <Route  path="/saidas" element={ infoUsuario ? <Saida /> : <Navigate to="/auth/login" />}   />
              <Route  path="/saidas/add" element={ infoUsuario ? <AddSaida /> : <Navigate to="/auth/login" />}   />
              <Route  path="/auth/login" element={ <Login />} exact />
              <Route  path="/auth/signup" element={ infoUsuario ? <SignUp /> : <Navigate to="/auth/login" />}   />
              <Route  path="/auth/users" element={  infoUsuario && infoUsuario.IsMaster ? <Users /> : <Navigate to="/auth/login" />}   />
              <Route  path="/admin/dashboard" element={infoUsuario && infoUsuario.IsMaster ? <Dashboard /> : <Navigate to="/auth/login" />}   />
              <Route  path="/admin/in-report" element={infoUsuario && infoUsuario.IsMaster ? <RelatorioEntrada /> : <Navigate to="/auth/login" />}   />
              <Route  path="/admin/out-report" element={infoUsuario && infoUsuario.IsMaster ? <RelatorioEntrada /> : <Navigate to="/auth/login" />}   />
              {/* Rota para a página 404 */}
              <Route path="/not-found" element={<NotFound />} />
              <Route path="*"element={<Navigate to="/not-found" />}/>
            </Routes>
          </SidebarComponent>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
