import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField,  Zoom } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Delete, Save } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { deleteSaida, updateSaida } from "../../redux/actions/saidaActions";
import LoadingMessage from "../../templates/Loading/LoadingMessage";
import MessageError from "../../templates/Error/MessageError";
import SuccessMessage from "../../templates/Success/SuccessMessage";

const validationSchema = Yup.object().shape({
  DescricaoSaida: Yup.string().required("A descrição é obrigatória"),
  ValorSaida: Yup.number().typeError("O valor deve ser numérico").required("O valor é obrigatório"),
  Beneficiario: Yup.string().required("O beneficiário é obrigatório"),
  // Adicione outras validações conforme necessário para os campos adicionais
});



const SaidaDialog = ({ item, onClose }) => {
  const dispatch = useDispatch();

  const updateSaidaStore = useSelector((state) => state.updateSaidaStore);
  const {loading, error, success } = updateSaidaStore;

  const deleteSaidaStore = useSelector((state) => state.deleteSaidaStore);
  const {loading:loadingDelete, error: errorDelete, success: successDelete } = deleteSaidaStore;

  const [updateFormSubmitted, setUpdateFormSubmitted] = useState(false);
  const [deleteFormSubmitted, setDeleteFormSubmitted] = useState(false);


  const handleSubmitUpdate = async (values,{ setSubmitting }) => {
    dispatch(updateSaida(values));
    setSubmitting(false);
    setUpdateFormSubmitted(true);
    setTimeout(() => {
      onClose();
    }, 5000);
  };

  const handleSubmitDelete = async () => {
  try {
    if (window.confirm(`Deseja eliminar o item nº ${item.Id} ?`)){
      dispatch(deleteSaida(item)); 
      setDeleteFormSubmitted(true);
      setTimeout(() => {
        onClose();
      }, 5000);
    }
  } catch (error) {
    // Lidar com qualquer erro de exclusão aqui, se necessário
    console.error("Erro ao excluir:", error);
  }
};

  return (
    <Dialog open={true} onClose={onClose} 
      TransitionComponent={Zoom}
      TransitionProps={{
        direction: "up",
        timeout: { enter: 1000, exit: 500 }, // Ajuste os valores de timeout conforme necessário
      }}
    >
      <DialogTitle textAlign="center">Editar Saída Nº {item.Id}</DialogTitle>
      <DialogContent>
        {updateFormSubmitted && loading && <LoadingMessage />}
          {updateFormSubmitted && error && (
            <MessageError variant="danger">
              Erro de atualização: {error}
            </MessageError>
          )}
          {updateFormSubmitted && success && (
            <SuccessMessage variant="success">
              {`${item.DescricaoSaida}\n registro atualizado com sucesso`}
            </SuccessMessage>
        )}
        {deleteFormSubmitted && loadingDelete && <LoadingMessage />}
          {deleteFormSubmitted && errorDelete && (
            <MessageError variant="danger">
              Erro de atualização: {error}
            </MessageError>
          )}
          {deleteFormSubmitted && successDelete && (
            <SuccessMessage variant="success">
              {`registro nª ${item.Id} deletado com sucesso`}
            </SuccessMessage>
        )}Delete
        <Formik
          initialValues={{ ...item }}
          validationSchema={validationSchema}
          onSubmit={handleSubmitUpdate}
        >
          {({ values, handleChange, handleBlur, errors, touched }) => (
            <Form>
              <Field
                as={TextField}
                fullWidth
                name="DescricaoSaida"
                label="Descrição"
                variant="outlined"
                margin="normal"
                value={values.DescricaoSaida}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.DescricaoSaida && touched.DescricaoSaida)}
                helperText={<ErrorMessage name="DescricaoSaida" />}
              />
              <Field
                as={TextField}
                fullWidth
                name="ValorSaida"
                label="Valor"
                variant="outlined"
                margin="normal"
                type="number"
                value={values.ValorSaida}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.ValorSaida)}
                helperText={<ErrorMessage name="ValorSaida" />}
              />
              <Field
                as={TextField}
                fullWidth
                name="Beneficiario"
                label="Beneficiário"
                variant="outlined"
                margin="normal"
                value={values.Beneficiario}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.Beneficiario)}
                helperText={<ErrorMessage name="Beneficiario" />}
              />
              
              <DialogActions>
                <Button onClick={handleSubmitDelete} variant="contained" color="secondary">
                  <Delete/> 
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  <Save/> 
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default SaidaDialog;
