
export const movimentoTypes = {
    // GETALL
    REQ_GET_MOVIMENTO: 'REQ_GET_MOVIMENTO',
    SUCCESS_GET_MOVIMENTO: 'SUCCESS_GET_MOVIMENTO',
    FAIL_GET_MOVIMENTO: 'FAIL_GET_MOVIMENTO',

    //UPDATE
    REQ_UPDATE_MOVIMENTO: 'REQ_UPDATE_MOVIMENTO',
    SUCCESS_UPDATE_MOVIMENTO: 'SUCCESS_UPDATE_MOVIMENTO',
    FAIL_UPDATE_MOVIMENTO: 'FAIL_UPDATE_MOVIMENTO',
    RESET_UPDATE_MOVIMENTO: 'RESET_UPDATE_MOVIMENTO',

    //DELETE
    REQ_DELETE_MOVIMENTO: 'REQ_DELETE_MOVIMENTO',
    SUCCESS_DELETE_MOVIMENTO: 'SUCCESS_DELETE_MOVIMENTO',
    FAIL_DELETE_MOVIMENTO: 'FAIL_DELETE_MOVIMENTO',
    RESET_DELETE_MOVIMENTO: 'RESET_DELETE_MOVIMENTO',

    //INSERT
    REQ_INSERT_MOVIMENTO: 'REQ_INSERT_MOVIMENTO',
    SUCCESS_INSERT_MOVIMENTO: 'SUCCESS_INSERT_MOVIMENTO',
    FAIL_INSERT_MOVIMENTO: 'FAIL_INSERT_MOVIMENTO',
    RESET_INSERT_MOVIMENTO: 'RESET_INSERT_MOVIMENTO',

    REQ_ONE_MOVIMENTO: "REQ_ONE_MOVIMENTO",
    SUCCESS_ONE_MOVIMENTO: "SUCCESS_ONE_MOVIMENTO",
    FAIL_ONE_MOVIMENTO: "FAIL_ONE_MOVIMENTO"

}
