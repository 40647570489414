import axios from 'axios';
import { relatorioTypes } from '../constants/relatorioTypes';

const baseUrl = process.env.REACT_APP_BASE_URL + "/relatorio/";

export const getRelatorioEntrada = () => async (dispatch, getState) => {
    dispatch({
        type: relatorioTypes.REQ_GET_RELATORIO,
    });

    const { userLoginStore: { infoUsuario } } = getState(); 

    try {
        const response = await axios.get(`${baseUrl}getentradasreport`, { 
            headers: { authorization: `Bearer ${infoUsuario.Token}` },
            responseType: "arraybuffer",            
        });

        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);

        dispatch({
            type: relatorioTypes.SUCCESS_GET_RELATORIO,
            payload: pdfUrl
        });

    } catch (error) {
        const message = error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({
            type: relatorioTypes.FAIL_GET_RELATORIO,
            payload: message
        });
    }
};
