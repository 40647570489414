import { resumoTypes } from "../constants/resumoTypes";

const initialState = {
    resumo: []
}

export function getResumoReducer (state = initialState, action) { 
    switch (action.type) {

        case resumoTypes.REQ_GET_RESUMO:
            return {
                loading: true,
            };

        case resumoTypes.SUCCESS_GET_RESUMO:
            return {
                loading: false,
                state,
                resumo: action.payload
            };

        case resumoTypes.FAIL_GET_RESUMO:
            return {
                loading: false,
                error: [...action.payload]
            };

        default:
            return state

    }
};