
export const saidaTypes = {
    // GETALL
    REQ_GET_SAIDA: 'REQ_GET_SAIDA',
    SUCCESS_GET_SAIDA: 'SUCCESS_GET_SAIDA',
    FAIL_GET_SAIDA: 'FAIL_GET_SAIDA',

    // GET CHART
    REQ_GET_SAIDA_CHART: 'REQ_GET_SAIDA_CHART',
    SUCCESS_GET_SAIDA_CHART: 'SUCCESS_GET_SAIDA_CHART',
    FAIL_GET_SAIDA_CHART: 'FAIL_GET_SAIDA_CHART',

    // GET COUNT
    REQ_GET_SAIDA_COUNT: 'REQ_GET_SAIDA_COUNT',
    SUCCESS_GET_SAIDA_COUNT: 'SUCCESS_GET_SAIDA_COUNT',
    FAIL_GET_SAIDA_COUNT: 'FAIL_GET_SAIDA_COUNT',

    //UPDATE
    REQ_UPDATE_SAIDA: 'REQ_UPDATE_SAIDA',
    SUCCESS_UPDATE_SAIDA: 'SUCCESS_UPDATE_SAIDA',
    FAIL_UPDATE_SAIDA: 'FAIL_UPDATE_SAIDA',
    RESET_UPDATE_SAIDA: 'RESET_UPDATE_SAIDA',

    //DELETE
    REQ_DELETE_SAIDA: 'REQ_DELETE_SAIDA',
    SUCCESS_DELETE_SAIDA: 'SUCCESS_DELETE_SAIDA',
    FAIL_DELETE_SAIDA: 'FAIL_DELETE_SAIDA',
    RESET_DELETE_SAIDA: 'RESET_DELETE_SAIDA',

    //INSERT
    REQ_INSERT_SAIDA: 'REQ_INSERT_SAIDA',
    SUCCESS_INSERT_SAIDA: 'SUCCESS_INSERT_SAIDA',
    FAIL_INSERT_SAIDA: 'FAIL_INSERT_SAIDA',
    RESET_INSERT_SAIDA: 'RESET_INSERT_SAIDA',

    REQ_ONE_SAIDA: "REQ_ONE_SAIDA",
    SUCCESS_ONE_SAIDA: "SUCCESS_ONE_SAIDA",
    FAIL_ONE_SAIDA: "FAIL_ONE_SAIDA"

}
